<script>

    import { navigate } from "svelte-navigator";

</script>
<div class="h-full w-full flex items-center flex-col justify-center">
	<p class="text-5xl mb-10 font-extrabold">
		404 - Not Found
	</p>
	<button class="btn btn-success btn-lg hover:btn-success hover:opacity-75" on:click={() => navigate("/")}>
		New Room
	</button>
</div>