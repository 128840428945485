
import { get } from 'svelte/store';
import { deferredInstallPrompt } from './../common/basicStores';
import { MessageType, type Message, type BeforeInstallPromptEvent} from "./commonWorkerUtils";


function listenForWaitingServiceWorker(reg: ServiceWorkerRegistration, callback: (reg: any) => void) {
	function awaitStateChange() {
		reg.installing?.addEventListener?.('statechange', function () {
			if (this.state === 'installed') callback(reg)
		})
	}
	if (!reg) return
	if (reg.waiting) return callback(reg)
	if (reg.installing) awaitStateChange()
	reg?.addEventListener?.('updatefound', awaitStateChange)
}

// reload once when the new Service Worker starts activating
let refreshing: boolean


navigator?.serviceWorker?.addEventListener?.('controllerchange', function () {
	if (refreshing) return
	refreshing = true
	window.location.reload()
})


export const preventBeforeInstallPrompt = async () => {
	const preventInstall = (e: BeforeInstallPromptEvent) => {
		e.preventDefault();
		deferredInstallPrompt.set(e);
	}

	window?.addEventListener?.('beforeinstallprompt' as any, preventInstall);

	return () => {
		window.removeEventListener('beforeinstallprompt' as any, preventInstall);
	}
}


export const handlePwaInstall = () => {
	const prompt = get(deferredInstallPrompt);
	// debugger
	prompt?.prompt();
}
export const sendFirebaseNotification = async ({ title, body, userId }: {
	title: string,
	body: string,
	userId: string
}) => {
	try {
		const url = new URL(`https://notify.xn--lkv.com/notify`);
		url.searchParams.append('title', title);
		url.searchParams.append('body', body);
		url.searchParams.append('user_id', `web__${userId}`);

		const resWeb = await fetch(url.href).then(res => res.json())
		url.searchParams.set('user_id', `mobile__${userId}`);
		const resMobile = await fetch(url.href).then(res => res.json())
		console.log({ resWeb, resMobile })
	} catch (e) {
		console.error(e)
	}
}

const initServiceWorker = async (): Promise<ServiceWorkerRegistration> => {
	if ('serviceWorker' in navigator) {
		const reg = await navigator.serviceWorker.register("/service-worker.js")

		listenForWaitingServiceWorker(reg, function (reg: any) {
			reg.waiting.postMessage({ type: MessageType.SKIP_WAITING })
		})

		return reg
	} else {
		throw new Error('Service worker not available')
	}
}


export { initServiceWorker }