import { ExtendedWritable, type IExtendedWritableEvents} from "./ExtendedWritable";
import cloneDeep from 'lodash-es/cloneDeep'
import { getProxy } from "./proxyDeep";
import get from 'lodash-es/get'
import set from 'lodash-es/set'




class ReactiveObject<T extends object, U extends IExtendedWritableEvents = any> extends ExtendedWritable<T, U>{

	public onDestroy: () => void = () => {};

	public proxy = getProxy<T>({
		get: (keyPath) => {
			const val = get(this.get(), keyPath, null);
			return val
		},

		set: (keyPath, value ) => {
			this.update(state => {
				set(state, keyPath, value);
				return state;
			});
			return true
		}
	})


	constructor(initialState: T){
		super(initialState);
	}

	getState(): T{
		return this.get();
	}

	setState(newState: T){
		this.set(newState);
	}


	clone(): ReactiveObject<T>{
		return new ReactiveObject<T>(cloneDeep(this.get()));
	}

	destroy(){
		this.onDestroy();
	}


	setItem<U extends keyof T>(key: U, value: T[U]): void{
		this.update((state) => {
			state[key] = value;
			return state;
		});
	}
	getItem<U extends keyof T>(key: U): T[U]{
		return this.get()[key];
	}

	updateItem<U extends keyof T>(key: U, updater: (value: T[U]) => T[U]): void{
		this.update((state) => {
			state[key] = updater(state[key]);
			return state;
		});
	}

}



export { ReactiveObject };