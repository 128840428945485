

export const getLocalStorageItem = (key: string): string | null => {
	try{
		return window.localStorage.getItem(key);
	}catch(err){
		console.error(err);
	}
	return null;
}

export const setLocalStorageItem = (key: string, value: string) => {
	try{
		window.localStorage.setItem(key, value);
	}catch(err){
		console.error(err);
	}
}


export const removeLocalStorageItem = (key: string) => {
	try{
		window.localStorage.removeItem(key);
	}catch(err){
		console.error(err);
	}
}
