<script lang="ts">
    import { playKnockKnockSound } from "@common/basicStores";


	let audio: HTMLAudioElement | undefined;

	let pageVisible: boolean = document.visibilityState === "visible";
	let timeout: any
	let playing: boolean = false;

	let stopOnPageVisible: boolean = false;


	const play = () => {
		clearTimeout(timeout);
		audio = new Audio("/personKnocking.mp3");
		audio.loop = true;
		audio.play();
		playing = true

		if(pageVisible){
			timeout = setTimeout(() => {
				stop()
			}, 1000);
		}else{
			stopOnPageVisible = true;
		}
	}

	const stop = () => {
		clearTimeout(timeout);
		if(audio){
			audio.pause();
			audio.currentTime = 0;
			playing = false

			audio = undefined;
			$playKnockKnockSound = false;
			console.log("stop knock knock sound")
		}
	}
	

	$: if($playKnockKnockSound){
		play();
	}else{
		stop();
	}

	$: if(pageVisible && stopOnPageVisible){
		stop();
		stopOnPageVisible = false;
	}
</script>


<svelte:window
	on:visibilitychange={() => {
		pageVisible = document.visibilityState === "visible";
	}}
/>