import { uid } from "uid";
import { ReactiveObject } from "./ReactiveObject";


export enum TaskMediaTypes{
	IMAGE = "image",
	VIDEO = "video",
	AUDIO = "audio",
	DOCUMENT = "document"
}

export interface ITaskMedia{
	id: string;
	mediaType: TaskMediaTypes;
	file: File;
}
export enum TaskStates{
	OPEN = "open",
	CLOSED = "closed",
	DELETED = "deleted",
	PAUSED = "paused"
}

export interface ITask{
	id: string;
	title: string;
	description: string;
	media: ITaskMedia[]
	state: TaskStates;
	createdAt: number;
	updatedAt: number;
}

export interface ITasks{
	tasks: ITask[]
}

export class Tasks extends ReactiveObject<ITasks>{
	constructor(options: Partial<ITasks> = {} as any){
		const _options:ITasks = {
			tasks: [],
			...options
		}
		super(_options)
	}

	getTask(id: string){
		return this.get().tasks.find((t) => t.id === id)
	}

	addTask(task: Partial<ITask>): ITask{
		const defaultOptions: ITask = {
			id: uid(),
			title: "",
			description: "",
			media: [],
			state: TaskStates.OPEN,
			createdAt: Date.now(),
			updatedAt: Date.now(),
			...task
		}
		const newTask = {
			...defaultOptions,
			...task
		}
		this.update((state) => {
			state.tasks = state.tasks.filter((t) => t.id !== newTask.id)
			state.tasks.push(newTask)
			return state
		})
		return newTask;
	}
	removeTask(id: string){
		this.update((state) => {
			state.tasks = state.tasks.filter((t) => t.id !== id)
			return state
		})
	}

	updateTask(
		id: string,
		callback: (task: ITask) => ITask
	): ITask{
		let updatedTask: ITask;
		this.update((state) => {
			const index = state.tasks.findIndex(t => t.id === id);
			if(index !== -1){
				state.tasks[index] = callback(state.tasks[index]);
				updatedTask = state.tasks[index]
			}
			return state;
		})
		return updatedTask!;
	}

}