export const shareContainerContextKey = {}

export const cacheName = 'babelbab';
export const channelName = 'babelbab-messages';
export const urlPrefix = "/share-api/"


const storageVersion = 4
export const calendarBroadcastChannelKey = "calendar-broadcast-channel";
export const calendarEventsStorageKey = `calendar-events-storage-version-${storageVersion}`;
export const listsStorageKey = "lists"
export const connectionManagerStorageKey = "connection-manager-storage"
export const contactListStorageKey = "contactListStorage"
export const networksStorageKey = "networks-storage";
export const rememberListStorageKey = "rememberListStorage";
export const threadsStorageKey = "threads"
export const userDataStorageKey = `userData-version-${storageVersion}`;