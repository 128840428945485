import { writable, type Writable, get } from 'svelte/store'
import { getLocalStorageItem, setLocalStorageItem } from './localStorage';

export interface IExtendedWritableEvents{
	[key: string]: {
		[key: string]: any
	}
}

export class ExtendedWritable<T, U extends IExtendedWritableEvents = any> extends EventTarget implements Writable<T> {

	public set: Writable<T>['set'];
	public update: Writable<T>['update'];
	public subscribe: Writable<T>['subscribe'];

	public _stopPresistance: () => void = () => { }

	constructor(...args: Parameters<typeof writable>) {
		super()
		const store:any = writable(...args);
		this.set = (...args) => store.set(...args)
		this.update = (...args) => store.update(...args)
		this.subscribe = (...args) => store.subscribe(...args)
	}

	get(): T {

		return get(this)
	}

	dispatch<K extends keyof U>(
		eventName: K,
		detail: U[K]
	){
		this.dispatchEvent(new CustomEvent(eventName as string, {
			detail
		}))
	}

	addListener<
		K extends keyof U,
	>(
		eventName: K,
		handler: (event: CustomEvent<U[K]>) => void
	): () => void{
		this.addEventListener(eventName as string, handler as any)
		return () => this.removeEventListener(eventName as string, handler as any)
	}
}



export const localStoragePresistanceStore = <T extends ExtendedWritable<Record<string, any>>>(store: T, exclude: string[], key: string): T => {
	if(store._stopPresistance){
		store._stopPresistance()
	}

	const storedValue = getLocalStorageItem(key)

	if (storedValue) {
		store.set(JSON.parse(storedValue))
	}
	
	store._stopPresistance = store.subscribe(value => {
		const val = { ...value }
		exclude.forEach((key) => {
			delete val[key]
		})
		setLocalStorageItem(key, JSON.stringify(val))
	})
	return store
}