<script lang="ts">
	import { globalMedia } from "@common/basicStores";
	import { onMount } from "svelte";

	const audio = new Audio("/nothing.wav");

	onMount(async () => {
		try {
			if (!$globalMedia.audioOutput.find((a) => a === audio)) {
				$globalMedia.audioOutput = [...$globalMedia.audioOutput, audio];
			}

			await waitForUserEvent();

			if(audio.paused){
				try{
					await audio.play()
				}catch(err){
					console.error(`Failed to play audio`, err)
				}
				audio.pause();
				audio.currentTime = 0;
			}
		} catch (err) {
			console.error(err);
		}
	});

	const enableMediaSessionApi = (
		audio: HTMLAudioElement,
	) => {
		if ("mediaSession" in navigator) {
			navigator.mediaSession.metadata = new MediaMetadata({
				title: "Babelbab",
				artist: "Babelbab",
				album: "Babelbab",
				
			});
			navigator.mediaSession.setActionHandler("play", () => {
				audio.play();
			});
			navigator.mediaSession.setActionHandler("pause", () => {
				audio.pause();
			});
			
			// disable seek
			navigator.mediaSession.setActionHandler("seekbackward", () => {});
			navigator.mediaSession.setActionHandler("seekforward", () => {});
			navigator.mediaSession.setActionHandler("seekto", () => {});

			// disable next track prev track
			navigator.mediaSession.setActionHandler("previoustrack", () => {});
			navigator.mediaSession.setActionHandler("nexttrack", () => {});
		}
	}

	const waitForUserEvent = () => {
		return new Promise<void>((resolve) => {
			const handler = () => {
				resolve();
				document.removeEventListener("click", handler);
				document.removeEventListener("touchstart", handler);
				
			};
			document.addEventListener("click", handler);
			document.addEventListener("touchstart", handler);
		});
	};
</script>
