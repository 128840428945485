import type { ICalendarEvent, IEvent, IEventChange } from "./calendar";
import {createInstance} from 'localforage'
import { uid } from "uid";
import dayjs from 'dayjs'
import { calendarEventsStorageKey } from "./constants";

export const getLocalCalendarStorage = () => {
	return createInstance({
		name: calendarEventsStorageKey
	})
}

export const getLocalCalendarEvents = () => {
	return getLocalCalendarStorage().getItem<ICalendarEvent[]>("events")
}


export const getLocalCalendarSubEvent = async (
	opts: {
		eventId: string
		subEventId: string
	}
): Promise<{
	subEvent: IEvent
	event: ICalendarEvent
}> => {
	const {eventId, subEventId} = opts
	const events = await getLocalCalendarEvents()
	const event = events?.find(e => e.id === eventId)
	if (!event) {
		throw new Error("Event not found")
	}
	const subEvent = event.events.find(e => e.id === subEventId)
	if (!subEvent) {
		throw new Error("Sub event not found")
	}
	return {subEvent, event}
}




export const getLocalCalendarEventAndSubEvent = async (opts: {
	eventId: string;
	subEventId: string;

}): Promise<{
	event: ICalendarEvent;
	subEvent: IEvent
} | undefined>=> {

	const events = await getLocalCalendarEvents()

	if (!events) {
		return undefined
	}

	const event = events.find(e => e.id === opts.eventId)

	if (!event) {
		return undefined
	}

	const subEvent = event.events.find(e => e.id === opts.subEventId)

	if (!subEvent) {
		return undefined
	}

	const allChanges = await getEventChanges()

	const newSubEvent = await applyChangesToSubEvent({
		subEvent,
		changes: allChanges
	})

	const newEvent = {
		...event,
		events: event.events.map(e => e.id === newSubEvent.id ? newSubEvent : e)

	}
	return {
		event: newEvent,
		subEvent: newSubEvent
	}
}


export const applyChangesToSubEvent = (opts: {
	subEvent: IEvent;
	changes: IEventChange[]
}) => {
	const {subEvent, changes} = opts
	const newSubEvent = {...subEvent}
	changes.forEach(change => {
		if(change.subEventId !== subEvent.id) {
			return
		}
		// check if start time is the latest in change
		if (change.start && change.start > newSubEvent.start) {
			newSubEvent.start = change.start
			newSubEvent.end = change.end
			newSubEvent.date = change.start	
		}
	})

	return newSubEvent
}

export const updateCalendarEvent = async (
	eventId: string,
	cb: (event: ICalendarEvent) => ICalendarEvent
) : Promise<ICalendarEvent>=> {
	const events = await getLocalCalendarEvents()
	const index = events?.findIndex(e => e.id === eventId)
	if (events && index && index > -1) {
		const event = events[index]
		const newEvent = cb(event)
		events[index] = newEvent
		await getLocalCalendarStorage().setItem("events", events)
		return newEvent
	}else{
		throw new Error("Event not found")
	}
}

export const updateCalendarSubEvent = async (
	eventId: string,
	subEventId: string,
	cb: (event: IEvent) => IEvent
) : Promise<ICalendarEvent>=> {
	const events = await getLocalCalendarEvents()
	const index = events?.findIndex(e => e.id === eventId)
	if (events && index && index > -1) {
		const event = events[index]
		const subEventIndex = event.events.findIndex(e => e.id === subEventId)
		if (subEventIndex > -1) {
			const subEvent = event.events[subEventIndex]
			const newSubEvent = cb(subEvent)
			event.events[subEventIndex] = newSubEvent
			events[index] = event
			await getLocalCalendarStorage().setItem("events", events)
			return event
		}else{
			throw new Error("Sub Event not found")
		}
	}else{
		throw new Error("Event not found")
	}
}


export const getEventChanges = async (): Promise<IEventChange[]> => {
	return await getLocalCalendarStorage().getItem("changes") || []
}

export const updateChanges = async (cb: (changes: IEventChange[]) => IEventChange[]) => {
	const events = await getEventChanges();
	const newEvents = cb(events)
	await getLocalCalendarStorage().setItem("changes", newEvents)
}


export const addCalendarChange = async (
	eventId: string,
	subEventId: string,
	change: IEvent,
) : Promise<void>=> {
	
	await updateChanges(changes => {
		return [
			...changes,
			{
				eventId,
				subEventId,
				end: change.end,
				start: change.start,
				startDate: change.start,
				endDate: change.start,
				id: uid(),
				userId: ""
			}
		]
	})
}


export enum BookEventViews{
	list = "list",
	recurring = "recurring",
}
