export enum MessageType {
  LISTEN_NOTIFICATIONS = 'LISTEN_NOTIFICATIONS',
  SKIP_WAITING = 'SKIP_WAITING',
  GET_BOOK = 'GET_BOOK',
  GET_IMAGE = 'GET_IMAGE',
}

export interface Message {
  type: MessageType
  payload: any;
}

export interface BeforeInstallPromptEvent extends Event {

  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed',
    platform: string
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;

}


export const getBroadcastChannel = (name: string): BroadcastChannel | undefined => {
  try {
    // check if BroadcastChannel is supported
    if (typeof BroadcastChannel === 'undefined') {
      return undefined;
    }

    const channel = new BroadcastChannel(name)
    return channel

  } catch (err) {
    console.error(`Error creating broadcast channel: ${err}`)
    return undefined
  }
}
