import { uid } from "uid";
import { ReactiveObject } from "./ReactiveObject";
import { createInstance } from "localforage";
import { listsStorageKey } from "./constants";

export interface IPost{
	id: string
	content: string
	authorId: string
	created: number;
	updated: number;
}

export interface IList{
	id:string;
	name: string;
	description: string;
	owner: string;
	created: number;
	updated: number;
	posts: IPost[];
}

export const getListStorageInstance = () => createInstance({
	name: listsStorageKey,
})


export class List extends ReactiveObject<IList>{
	public storage = getListStorageInstance()

	public loaded: boolean | Promise<boolean> = false

	constructor(
		data: Partial<IList> = {}
	){
		const defaults: IList = {
			id: uid(),
			name: "",
			description: "",
			owner: "",
			created: Date.now(),
			updated: Date.now(),
			posts: []
		}

		super({...defaults, ...data})
	}


	async load(){
		let resolveLoadedPromise: (value: boolean) => void = () => {}
		this.loaded = new Promise((res) => {
			resolveLoadedPromise = res
		})

		const data: null | IList = await this.storage.getItem(this.get().id)
		if(data){
			this.set(data)
		}
		resolveLoadedPromise(true)
		return data
	}

	async save(){
		await this.storage.setItem(this.get().id, this.get())
	}


	

}


export interface IListManager{
	listIds: string[]
	lists: List[];
}

export class ListManager extends ReactiveObject<IListManager>{
	public storage = getListStorageInstance()

	public loaded: boolean | Promise<boolean> = false

	constructor(
		data: Partial<IListManager> = {}
	){
		const defaults: IListManager = {
			listIds: [],
			lists: []
		}

		super({...defaults, ...data})
	}

	createNewList(listOpts: IList){
		const list = new List(listOpts)

		this.update((state) => {
			state.listIds.push(list.get().id)
			state.lists.push(list)

			return state
		})
		
		return list
	}

	removeList(listId: string){
		this.update((state) => {
			state.listIds = state.listIds.filter((id) => id !== listId)
			state.lists = state.lists.filter((list) => list.get().id !== listId)
			return state
		})
	}

	getList(id: string){
		return this.get().lists.find((list) => list.get().id === id)
	}

	async getAndLoadList(id: string): Promise<List | undefined>{
		const list = this.getList(id)
		if(list){
			return list
		}
		return await this.loadListInstance(id)
	}

	async loadListInstance(id: string){
		if(this.getList(id)){
			return this.getList(id)
		}
		const list = new List({id})
		await list.load()
		this.update((state) => {
			state.lists.push(list)
			return state
		})
		return list
	}

	async loadListIds(loadInstances: boolean = false){
		const keys = await this.storage.keys()
		this.update((state) => {
			state.listIds = keys
			return state	
		})
		if(loadInstances === true){
			await this.loadListInstances(keys)
		}
	}

	async loadListInstances(listIds: string[]){
		const listInstances = listIds.map((id) => new List({id}))
		await Promise.all(listInstances.map((list) => list.load()))
		this.update((state) => {
			state.lists = listInstances
			return state
		})
		return listInstances
	}
}

