<svg
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
  role="img"
  class="w-full h-full"
  preserveAspectRatio="xMidYMid meet"
  viewBox="0 0 24 24"
  ><path
    fill="currentColor"
    d="m12.815 12.197l-7.532 1.255a.5.5 0 0 0-.386.318L2.3 20.728c-.248.64.421 1.25 1.035.942l18-9a.75.75 0 0 0 0-1.341l-18-9c-.614-.307-1.283.303-1.035.942l2.598 6.958a.5.5 0 0 0 .386.318l7.532 1.255a.2.2 0 0 1 0 .395Z"
  /></svg
>
