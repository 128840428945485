import type {RoomStore} from '@common/roomStore'
import { ReactiveObject } from './ReactiveObject';


export interface IActiveRoomStore{
	roomId: string;
	store: RoomStore;
}
export interface ILocalRoom{
	mainStore?: RoomStore;
	activeRoomStores: IActiveRoomStore[]
}

export class LocalRoom extends ReactiveObject<ILocalRoom>{
	constructor(opts: Partial<ILocalRoom>){
		super({
			mainStore: undefined,
			activeRoomStores: [],
			...opts
		});
	}
}