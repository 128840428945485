<script lang="ts">
  import { deferredInstallPrompt } from '@common/basicStores'
  import type {BeforeInstallPromptEvent} from '@ServiceWorker/commonWorkerUtils'

  const handleBeforeInstallPrompt: any = (e: BeforeInstallPromptEvent) => {
    e.preventDefault()
    $deferredInstallPrompt = e
  }
</script>

<svelte:window on:beforeinstallprompt={handleBeforeInstallPrompt} />
