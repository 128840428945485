<script lang="ts">
	import { mainIframe } from "@common/basicStores";
import Cancel from "../../icons/Cancel.svelte";


</script>

{#if $mainIframe}
	<div class="flex relative group flex-col w-full h-full bg-base-300 text-base-content">
		<iframe
			
			class="flex-grow"
			src="{$mainIframe.src}"
			title="{$mainIframe.title}"
		/>

		<div class="group-hover:block hidden absolute top-2 left-2">
			<button on:click={() => {
				$mainIframe = undefined;
			}} class="btn btn-ghost btn-sm btn-circle">
				<span class="inline-block h-5 w-5">
					<Cancel />
				</span>
			</button>
		</div>

	</div>
{/if}
