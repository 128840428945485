<svg
  class="h-full w-full"
  viewBox="0 0 236 236"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M39.8098 78.6666H39.3333C34.1174 78.6666 29.1151 80.7387 25.4269 84.4269C21.7387 88.1151 19.6667 93.1174 19.6667 98.3333V137.667C19.6667 142.883 21.7387 147.885 25.4269 151.573C29.1151 155.261 34.1174 157.333 39.3333 157.333H50.8559L94.2613 200.739C106.65 213.127 127.833 204.357 127.833 186.833V168.824L108.167 148.681V186.831L61.8822 140.547C60.0381 138.703 57.537 137.667 54.929 137.667H39.3333V98.3333H54.929C56.1244 98.3333 57.2974 98.1157 58.3949 97.7023L39.8098 78.6666ZM108.167 101.443V49.1688L82.3428 74.9926L68.6029 60.9197L94.2613 35.2613C106.65 22.8729 127.833 31.6428 127.833 49.1667V121.586L124.306 117.973L108.167 101.443ZM162.342 156.931L148.542 142.797C150.933 139.852 152.89 136.572 154.347 133.055C156.325 128.282 157.342 123.166 157.342 118C157.342 112.834 156.325 107.718 154.347 102.945C152.37 98.1725 149.472 93.836 145.818 90.1834C141.978 86.3438 141.977 80.1177 145.817 76.277C149.656 72.4364 155.882 72.4355 159.723 76.2752C165.203 81.754 169.551 88.2588 172.517 95.4181C175.483 102.577 177.009 110.251 177.009 118C177.009 125.749 175.483 133.423 172.517 140.582C170.04 146.56 166.6 152.082 162.342 156.931ZM189.864 185.12L176.107 171.03C182.251 164.298 187.184 156.542 190.679 148.105C194.633 138.56 196.667 128.331 196.667 118C196.667 107.669 194.633 97.4397 190.679 87.8953C186.726 78.351 180.931 69.6788 173.626 62.3739C169.786 58.5338 169.786 52.3077 173.626 48.4675C177.466 44.6273 183.692 44.6273 187.533 48.4674C196.664 57.5985 203.907 68.4387 208.849 80.3692C213.791 92.2996 216.334 105.087 216.334 118C216.334 130.913 213.791 143.7 208.849 155.631C204.335 166.527 197.902 176.514 189.864 185.12Z"
    fill="currentColor"
  />
  <path
    d="M30 45L106.5 123.5L183 202"
    stroke="currentColor"
    stroke-width="17"
    stroke-linecap="round"
  />
</svg>
