import type { SvelteComponentTyped } from "svelte";
import { uid } from "uid";
import { ReactiveObject } from "./ReactiveObject";



export enum NotificationTypes{
	SUCCESS = "SUCCESS",
	ERROR = "ERROR",
	WARNING = "WARNING",
	INFO = "INFO"
}

export interface INotificationAction{
	label?: string;
	class?: string;
	onClick?: () => void;
	component?: typeof SvelteComponentTyped;
	icon?: typeof SvelteComponentTyped
	getCloseMethod?: (method: () => void) => void;
}

export enum NotificationTargetView{
	"BottomBar" = "BottomBar",
	"Toast" = "Toast",
	"Modal" = "Modal",
	"Os" = "Os"
}

export interface INotification{
	id: string;
	message: string;
	type?: NotificationTypes;
	duration?: number;
	component?: typeof SvelteComponentTyped;
	icon?: typeof SvelteComponentTyped
	actions?: INotificationAction[];
	class?: string;
	targetView?: NotificationTargetView[];
}
export interface INotificationControllerOptions{
	queue: INotification[];
}

export class NotificationController extends ReactiveObject<INotificationControllerOptions>{
	constructor(options: Partial<INotificationControllerOptions> = {} as any){
		const _options:INotificationControllerOptions = {
			queue: [],
			...options
		}
		super(_options)
	}
	add(snackbar: Partial<INotification>){
		const defaultOptions: INotification = {
			id: uid(),
			message: "",
			targetView: snackbar.targetView || [NotificationTargetView.BottomBar],
			...snackbar
		}


		this.update((state) => {
			state.queue.push({
				...defaultOptions,
				...snackbar
			})
			return state
		})
	}
	
	clear(){
		this.update((state) => {
			state.queue = []
			return state
		})
	}

	updateNotification(id: string, callback: (snackbar: INotification) => INotification){
		this.update((state) => {
			const index = state.queue.findIndex(snackbar => snackbar.id === id);
			if(index !== -1){
				state.queue[index] = callback(state.queue[index]);
			}
			return state;
		})
	}
}