

let isAlreadyInteracted = false;


export const waitForPageInteraction = (
	skipIfAlreadyInteracted:boolean = false,
	callback:(cancelWaiting: () => void) => void = () => {}
): Promise<void> => {
	if(skipIfAlreadyInteracted){
		if(isAlreadyInteracted){
			return Promise.resolve();
		}
	}
	return new Promise((resolve, reject) => {
		const onInteraction = () => {
			window.removeEventListener("pointerdown", onInteraction);
			window.removeEventListener("keydown", onInteraction);
			resolve();
		};

		callback(() => {
			window.removeEventListener("pointerdown", onInteraction);
			window.removeEventListener("keydown", onInteraction);
			reject(
				new Error("waitForPageInteraction was cancelled")
			);
		});

		window.addEventListener("pointerdown", onInteraction);
		window.addEventListener("keydown", onInteraction);
	});
}