
<div class="flex flex-col w-full h-full">
	<div class="w-full">
		<slot name="header"></slot>
	</div>
	<div class="relative flex-grow">
		<div class="absolute inset-0 pb-2">
			<slot name="messages"></slot>
		</div>
	</div>
	<div class="w-full">
		<slot name="meta"></slot>
	</div>

	<div class="flex flex-row items-center gap-4">
		<div class="relative flex-grow h-12">
			<div class="absolute inset-0">
				<slot name="input"></slot>
			</div>
		</div>
		<div class="relative flex gap-2">
			<slot name="voiceButton"></slot>
			<slot name="sendButton"></slot>
		</div>
	</div>
</div>