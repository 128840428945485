<script lang="ts">
	import { notificationController } from "@common/basicStores";
	import { NotificationTargetView, NotificationTypes, type INotification } from "@common/notifications";
	import { slide } from "svelte/transition";


	let currentNotification: INotification | undefined
	let timeoutId: any


	const handleRemoveNotification = (snackbar: INotification) => {
		clearTimeout(timeoutId)
		timeoutId = setTimeout(() => {
			if($notificationController.queue.find(s => s === snackbar)){
				$notificationController.queue = $notificationController.queue.filter(
					(s) => s !== snackbar
				);
			}
		}, snackbar.duration || 2000);
	};

	$: queue = $notificationController.queue.filter(e => e.targetView?.includes(NotificationTargetView.BottomBar));
	$: currentNotification = queue.length > 0 ? queue[0] : undefined;

	$: if (currentNotification) {
		handleRemoveNotification(currentNotification);
	}
</script>


{#if currentNotification}
	<div
		transition:slide={{
			duration: 400,
		}}
		class:warning-type={currentNotification.type === NotificationTypes.WARNING}
		class:success-type={currentNotification.type === NotificationTypes.SUCCESS}
		class:error-type={currentNotification.type === NotificationTypes.ERROR}
		class:info-type={currentNotification.type === NotificationTypes.INFO}
		class="notificationContainer {currentNotification.class ? currentNotification.class : 'text-base-content'}"
	>
		{#if currentNotification.component}
			<svelte:component
				this={currentNotification.component}
				snackbar={currentNotification}
			/>
		{:else}
			<div class="h-14 text-sm w-full flex items-center justify-center">
				<span class='mx-2 inline-block snackbar-message'>
					{currentNotification.message}
				</span>
				{#if currentNotification.actions !== undefined && currentNotification.actions.length > 0}
					<div class="snackbarActions">
						{#each currentNotification.actions as action}
							{#if action.component}
								<svelte:component
									this={action.component}
									{action}
									snackbar={currentNotification}
								/>
							{:else}
								<button 
									class="btn btn-sm mx-1 {action.class || ""}"
									on:click={action.onClick}
									title="{action.label}"
								>	
									{action.label}
								</button>
							{/if}
						{/each}
					</div>
				{/if}
			</div>
		{/if}
	</div>
{/if}


<style lang="postcss">
	.notificationContainer{
		z-index: 1000000;
		@apply fixed bottom-0 border-t border-base-content border-opacity-20 bg-base-200 left-0 right-0;
	}

	.notificationContainer.warning-type{
		@apply bg-warning;
	}

	.notificationContainer.success-type{
		@apply bg-success;
	}

	.notificationContainer.error-type{
		@apply bg-error;
	}

</style>