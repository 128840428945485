<svg
	viewBox="0 0 307 299"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	class="h-full w-full"
>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M27.7288 41.5994C26.6861 42.2948 25.7067 43.0956 24.8074 43.9949C21.209 47.5932 19.1875 52.4737 19.1875 57.5625V211.062C19.1875 216.151 21.209 221.032 24.8074 224.63C28.4057 228.228 33.2862 230.25 38.375 230.25H115.125V268.625H76.75V287.812H230.25V268.625H191.875V230.25H214.414L195.427 211.062H38.375V57.5625H43.5256L27.7288 41.5994ZM241.853 211.062H268.625V57.5625H89.9522L70.9646 38.375H268.625C273.714 38.375 278.594 40.3965 282.193 43.9949C285.791 47.5932 287.812 52.4737 287.812 57.5625V211.062C287.812 216.151 285.791 221.032 282.193 224.63C278.594 228.228 273.714 230.25 268.625 230.25H260.841L241.853 211.062ZM134.312 268.625H172.688V230.25H134.312V268.625Z"
		fill="currentColor"
	/>
	<path
		d="M33 22.5L277.5 269.5"
		stroke="currentColor"
		stroke-width="17"
		stroke-linecap="round"
	/>
</svg>
