import { uid } from "uid"


export const generateUniqueRoomId = () => {
	return generateRandomId()
}

export const generateRandomId = () => {
	// lowercase, alphanumeric, separated by dashes
	return Array(3).fill(null).map(() => {
		return Array(3).fill(null).map(() => {
			const chars = "abcdefghijklmnopqrstuvwxyz"
			return chars[Math.floor(Math.random() * chars.length)]
		}).join("")
	}).join("-")
}
